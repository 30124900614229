<template>
  <b-modal
    id="phone-call-create-modal"
    title="Добавить звонок"
    ok-only
    centered
    @ok="updateCall"
  >
    <b-card-text>
      <b-row>
        <b-col>
          <p>{{ callForm.referral_source }}</p>
          <b-form-group label="Имя">
            <b-form-input v-model="callForm.name" />
          </b-form-group>
          <b-form-group label="Телефон">
            <b-form-input v-model="callForm.phone" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group class="mt-2" label="Статус">
        <b-form-select
          v-model="callForm.status"
          :options="statuses"
          text-field="display_name"
          value-field="value"
        />
      </b-form-group>
      <b-form-group label="Комментарии">
        <b-form-textarea v-model="callForm.comment" />
      </b-form-group>
    </b-card-text>
  </b-modal>
</template>

<script>
export default {
  name: 'PhoneCallCreateModal',
  data() {
    return {
      callForm: {
        referral_source: 'Звонок',
      },
      statuses: [],
    }
  },
  created() {
    this.getCallOptions()
  },
  methods: {
    async getCallOptions() {
      const res = await this.$http.options('admin/phone-calls/')
      this.statuses = res.data.actions.POST.status.choices
    },
    async updateCall() {
      await this.$http.post('admin/phone-calls/', this.callForm)
      this.$emit('callUpdated')
    },
  },
}
</script>
