<template>
  <b-modal
    id="phone-call-modal"
    title="Результат звонка"
    ok-only
    centered
    @ok="updateCall"
  >
    <b-card-text>
      <b-row>
        <b-col cols="12" md="6">
          <h4>{{ callForm.phone }}</h4>
        </b-col>
        <b-col>
          <b-form-group label="Имя">
            <b-form-input v-model="callForm.name" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group class="mt-2" label="Статус">
        <b-form-select
          v-model="callForm.status"
          :options="statuses"
          text-field="display_name"
          value-field="value"
        />
      </b-form-group>
      <b-form-group label="Комментарии">
        <b-form-textarea v-model="callForm.comment" />
      </b-form-group>
    </b-card-text>
  </b-modal>
</template>

<script>
export default {
  name: 'PhoneCallModal',
  props: ['phoneCall'],
  data() {
    return {
      callForm: {},
      statuses: [],
    }
  },
  watch: {
    phoneCall() {
      this.callForm = this.phoneCall
    },
  },
  created() {
    this.getCallOptions()
  },
  methods: {
    async getCallOptions() {
      const res = await this.$http.options('admin/phone-calls/')
      this.statuses = res.data.actions.POST.status.choices
    },
    async updateCall() {
      await this.$http.patch(
        `admin/phone-calls/${this.callForm.id}/`,
        this.callForm
      )
      this.$emit('callUpdated')
    },
  },
}
</script>
