<template>
  <div class="phone-calls-list">
    <b-row>
      <b-col cols="12" md="3" sm="6" class="mb-1 mb-md-0">
        <b-form-input
          v-model="search"
          debounce="500"
          placeholder="Поиск по номеру телефона..."
        />
      </b-col>
      <b-col cols="12" md="3" sm="6">
        <b-form-input
          v-model="referral_search"
          debounce="500"
          placeholder="Поиск по источнику..."
        />
      </b-col>
      <b-col class="d-flex justify-content-end mt-1 mt-md-0">
        <b-button v-b-modal.phone-call-create-modal> Добавить</b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-form-group>
          <b-form-radio-group
            id="btn-radios-1"
            v-model="selectedStatus"
            :disabled="isLoading"
            :options="statusChoices"
            button-variant="outline-primary"
            buttons
            name="radios-btn-default"
            text-field="display_name"
            value-field="value"
            class="flex-wrap"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <b-card>
          <b-form-radio-group
            v-if="selectedStatus > 2"
            v-model="result"
            :options="resultChoices"
            text-field="title"
            value-field="value"
            buttons
            button-variant="outline-primary"
            class="mb-4"
          />
          <b-overlay :show="isLoading" rounded="sm">
            <b-table
              :fields="fields"
              :items="phoneCalls"
              empty-text="Нет звонков"
              responsive
              show-empty
              @row-clicked="openModal"
            >
              <template #head(created)="data">
                <span @click="toggleOrdering('created')">
                  {{ data.label }} <b-icon-sort-alpha-down />
                </span>
              </template>
              <template #head(phone)="data">
                <span @click="toggleOrdering('phone')">
                  {{ data.label }} <b-icon-sort-alpha-down />
                </span>
              </template>
              <template #head(name)="data">
                <span @click="toggleOrdering('name')">
                  {{ data.label }} <b-icon-sort-alpha-down />
                </span>
              </template>
              <template #cell(result)="data">
                <span v-if="data.item.has_deal"> Есть сделка </span>
                <span v-else>
                  {{ data.value }}
                </span>
              </template>
            </b-table>
          </b-overlay>
          <b-row
            v-if="pageCount > 1"
            class="d-flex justify-content-center mt-2"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalRows"
              aria-controls="my-table"
            ></b-pagination>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <phone-call-modal :phone-call="selectedCall" @callUpdated="getCalls" />
    <phone-call-create-modal @callUpdated="getCalls" />
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import PhoneCallModal from '@/views/phone-calls/components/PhoneCallModal'
import PhoneCallCreateModal from '@/views/phone-calls/components/PhoneCallCreateModal'

export default {
  name: 'PhoneCallsList',
  components: { PhoneCallCreateModal, PhoneCallModal },
  data() {
    return {
      phoneCalls: [],
      selectedCall: {},
      selectedStatus: 0,
      statusChoices: [],
      currentPage: 1,
      search: '',
      referral_search: '',
      ordering: '',
      totalRows: 0,
      perPage: 50,
      isLoading: false,
      resultChoices: [
        { title: 'Заявка', value: 'application' },
        { title: 'Сделка', value: 'deal' },
      ],
      result: null,
      fields: [
        {
          key: 'created',
          label: 'Дата заявки',
          formatter: (value) => {
            return `${DateTime.fromISO(value).toFormat('dd.LL.yyyy HH:mm')}`
          },
        },
        {
          key: 'phone',
          label: 'Телефон',
        },
        {
          key: 'name',
          label: 'Имя',
        },
        {
          key: 'status_display',
          label: 'Статус',
        },
        {
          key: 'result',
          label: 'Результат',
        },
        {
          key: 'comment',
          label: 'Комментарии',
        },
        {
          key: 'referral_source',
          label: 'Источник',
        },
      ],
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalRows / this.perPage)
    },
  },
  watch: {
    selectedStatus() {
      this.currentPage = 1
      if (![2, 3].includes(this.selectedStatus)) {
        this.result = null
      }
      this.getCalls()
    },
    search() {
      this.currentPage = 1
      this.getCalls()
    },
    referral_search() {
      this.currentPage = 1
      this.getCalls()
    },
    ordering() {
      this.getCalls()
    },
    currentPage() {
      this.getCalls()
    },
    result() {
      this.getCalls()
    },
  },
  created() {
    this.getCallOptions()
    this.getCalls()
  },
  methods: {
    async getCallOptions() {
      const res = await this.$http.options('admin/phone-calls/')
      this.statusChoices = res.data.actions.POST.status.choices
    },
    async getCalls() {
      this.isLoading = true
      const res = await this.$http.get(
        `admin/phone-calls/?status=${this.selectedStatus}`,
        {
          params: {
            page: this.currentPage,
            phone__icontains: this.search,
            referral_source: this.referral_search,
            ordering: this.ordering,
            result: this.result,
          },
        }
      )
      this.phoneCalls = res.data.results
      this.totalRows = res.data.count
      this.selectedCall = {}
      this.isLoading = false
    },
    openModal(item) {
      this.selectedCall = item
      this.$bvModal.show('phone-call-modal')
    },
    toggleOrdering(name) {
      if (name.includes(this.ordering)) {
        this.ordering = `-${name}`
      } else {
        this.ordering = name
      }
    },
  },
}
</script>
